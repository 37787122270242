<template>
  <div>
    <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{text}}</span> 
    <v-btn v-if="showing" small fab dark color="secondary" @click="display(false)">
      <v-icon dark>mdi-close-circle</v-icon>
    </v-btn>
    <v-tooltip v-else-if="tooltip" color="blue-grey darken-4" left>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" small fab dark color="secondary" @click="display(true)">
          <v-icon dark>{{openIcon}}</v-icon>
        </v-btn>
      </template>
      <slot>{{tooltip}}</slot>
    </v-tooltip>
    <v-btn v-else small fab dark color="secondary" @click="display(true)">
      <v-icon dark>{{openIcon}}</v-icon>
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'AddButton',
    props: {
      text: {
        default: '',
      },
      tooltip: {
        default: '',
      },
      showing: {
        default: false,
      },
      openIcon: {
        default: "mdi-plus",
      },
    },
    methods: {
      display(show) {
        this.$emit('toggle', show);
      },
    }

}
</script>

